
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row,SendRemainderButton,DeleteUserButton,ControlButtonsBar, Layout, ButtonsContainer,HoverableDiv } from './styles';
import { ImageUrl } from "../../utils/constants";
import {  RowCardPeopleView,RowCardPeopleHeader } from './styles';
import { connect } from 'react-redux';  
import { withTranslation } from 'react-i18next';
import ParticipantLocationPopup from '../NewEmployeePopupV2/participantsLocation';
// import {getNotRegisteredUsersList, sendRemainderByIds} from "../../redux/constants/apiConstants";
import {sendRemainderByIds, deleteInactiveUserById} from "../../redux/constants/apiConstants";
import { BASE_URL } from "../../utils/config";
import { toast } from "react-toastify";
import { CalendarHeader, CalendarWrapper, ConfirmVisitText, StyledModal, ButtonContainer,ButtonContainerV1,ButtonV2 } from '../PeopleHomeV2/ManageBuddies/styles';
import LazyImage from '../common/LazyImage/LazyImage';
import _ from 'lodash';
import { WarningIcon } from '../../utils/icons';

class InactiveUsersListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      initiativeId: null,
      initiativeName: null,
      inactiveUsersList: this.props.inactiveUsersList,
      locationData:this.props.locationData,
      departmentData:this.props.departmentData,
      selectedParticipant: [],
      deleteTeamIDS:[],
      ReminderEmployess: null,
      showNote: false,
      selectPage:0,
      showSelectPage : false,
      selectAll: 0,
      showPopup : false,
      showDeleteUserPopup: false,
      showSelectAll : false,
      isButtonEnabled: false,
    };
    window.console.log(this.props);
  }

  

  componentDidMount() {
    // window.console.log('inside did mount',this.props);
    //const { employeeList } = this.props;
    //window.console.log("inside list view", this.props);
    //this.fetchInactiveUsersList(plp.companyId);
    //this.setState({ inactiveUsersList:this.props.inactiveUsersList});
    this.handleShowSelectAllButton(this.props);
  }

  handleShowSelectAllButton(data){
    window.console.log('inside handle',data);
  }

  onEnterMouse = (uid, id, name) => {
    this.setState({
      userId: uid,
      initiativeId: id,
      initiativeName: name,
    });
  };

  onLeaveMouse = () => {
    this.setState({
      userId: null,
      initiativeId: null,
    });
  };

  activeTick = (index) => {
    // const { inactiveUsersList, updateParentState } = this.props;
    const selectedId = [...this.state.selectedParticipant];
    let objIndex = selectedId.findIndex((id) => id == index);
    objIndex == -1 ? selectedId.push(index) : selectedId.splice(objIndex, 1);

    let tempCount = 0;

    this.props.inactiveUsersList.map((employee) => employee.days_left && tempCount++)

    if(this.props.inactiveUsersList.length == (selectedId.length+tempCount)){
      this.setState({
        selectAll: 1,
        selectPage: 0,
        isButtonEnabled: true
      });
    }
    else if (selectedId.length > 0) {
      this.setState({
        selectAll: 2,
        selectPage: 0,
        isButtonEnabled: true,
      });
    }
    else{
      this.setState({
        selectAll: 0,
        selectPage: 0,
        isButtonEnabled: false,
      });
    }
    // window.console.log("83 line selectall :- ", this.state.selectAll);
    this.setState({
      selectedParticipant: selectedId,
    },() => {
      this.props.updateParentState({
        selectAll: this.state.selectAll,
        selectedParticipant: this.state.selectedParticipant,
      });
    });
    // localStorage.setItem("selectedEmployeeList", JSON.stringify(selectedArray))
  };

  handelSelectAll = (isSelected) => {
    // const { inactiveUsersList, updateParentState } = this.props;
    this.setState({
      selectAll: isSelected ? 0 : 1,
      selectPage: 0,
      isButtonEnabled: !isSelected,
    });
    let allUserIds = [];
    if (this.state.selectAll === 0) {
      this.props.inactiveUsersList && this.props.inactiveUsersList.map((employee) => {
        if(!employee.days_left){
          allUserIds.push(employee.id);
        }
       
      });
    }
    this.setState({
      selectedParticipant: allUserIds,
    },() => {
      this.props.updateParentState({
        selectAll: this.state.selectAll,
        selectedParticipant: this.state.selectedParticipant,
      });
    } );
  };

  handelSelectPage= (isSelected) => {
    // window.console.log("id for selection",isSelected)
    this.setState({
      selectPage: isSelected ? 0 : 1,
      selectAll: 0,
      isButtonEnabled: !isSelected && !this.props.daysLeft,
    });
    let currentPageUserIds = [];
    if (this.state.selectPage === 0) {
      if(this.props.inactiveUsersList && this.props.inactiveUsersList.length > 0 ) {
        this.props.inactiveUsersList.map((employee) => {
          if(!employee.days_left){
            currentPageUserIds.push(employee.id);
          }
        });
      }
    }
    this.setState({
      selectedParticipant: currentPageUserIds,
    });
  };

  

  handleSendReminder = (participants)=>{
    this.setState(
      {
        showPopup : true
      },
      () => {
        this.sendReminderApiCall(participants);
      }
    );
  }

  sendReminderApiCall = async (participants) => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let payload = {
      company_id: this.props.plp.companyId,
      user_ids: this.state.selectAll===1 && !this.props.searchText && !this.props.locationText && !this.props.departmentText ? [] : participants,
      sent_req_to_all: this.state.selectAll===1 && !this.props.searchText && !this.props.locationText && !this.props.departmentText ? true : false
    };
    let url = BASE_URL + sendRemainderByIds;
    await fetch(url, {
      method: "POST",
      headers: {
        AUTHTOKEN: AuthToken,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (data.data.success_count) {
              this.setState({ ReminderEmployess: data.data.success_count , showPopup : true} );
            } else {
              this.setState({ ReminderEmployess: 0 });
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  deleteInactiveUser = async (participants) => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let payload = {
      company_id: this.props.plp.companyId,
      user_ids: this.state.selectAll=== 1 && !this.props.searchText && !this.props.locationText && !this.props.departmentText ? [] : participants,
      sent_req_to_all: this.state.selectAll=== 1 && !this.props.searchText && !this.props.locationText && !this.props.departmentText ? true : false
    };
    let url = BASE_URL + deleteInactiveUserById;
    await fetch(url, {
      method: "POST",
      headers: {
        AUTHTOKEN: AuthToken,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        res
          .json()
          .then((data) => {

            if(data.status === "success"){
              this.props.fetchInactiveUsersList();
            }
            

            if (data.data.success_count) {
              // this.props.fetchInactiveUsersList();
              this.setState({ ReminderEmployess: data.data.success_count , showPopup : true, showDeleteUserPopup: false});
              // window.location.reload()
            } else {
              this.setState({ ReminderEmployess: 0, showDeleteUserPopup: false });
              // window.location.reload()
            }
            window.location.reload();
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };


  showDeletePopup = (id) => {
    this.setState(
      {
        showDeleteUserPopup: true, deleteTeamIDS:id
      }
    );
  };

  closUserDeletePopup = () => {
    this.setState({ showDeleteUserPopup: false });
  };

  fallBack = (e) => {
    e.target.src = "/public/images/default.png";
  };
  goToPersonalProfile = (employeeId) => {
    const { history } = this.props;
    history.push(`/company/people/${employeeId}/personal-profile`);
  };

  handlePopup = (value) => {
    this.setState({showPopup : value})
    window.location.reload()
  }
  capitalizeFirstLetter = (name) => {
    if (!name) return "";
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  
  render() {
    const { showEmployeeDetails, selectedEmployee, searchAmigo, t, plp, inactiveUsersList, showAll, remainingInactiveUser, inactiveUserCount, isShowLocation, isShowDepartment} =
      this.props;
    const { selectAll,selectPage, isButtonEnabled, deleteTeamIDS } = this.state;

    // window.console.log("testing data", isButtonEnabled);
    // const handleInviteUsers = () => {
    //   if (selectAll === 1) {
    //     this.handleSendReminderToAllUser();
    //   } else if (selectPage === 1) {
    //     this.handleSendReminder(this.state.selectedParticipant);
    //   }
    // };
    // let uidArray = [];
    // this.state.selectedParticipant.map((obj) => {
    //   uidArray.push(obj.uid)
    // })
    return (
      <Layout style={{padding:'0',margin:'0'}}>
        <div>
          <ControlButtonsBar>
            {remainingInactiveUser > 1 ?
              (<div
                style={{
                  marginLeft: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  minHeight:'70px'
                }}
              >
                
                <HoverableDiv
                  onClick={() => this.handelSelectAll(this.state.selectAll)}
                >
                  {selectAll < 1 ? (
                    ""
                  ) : selectAll < 2 ? (
                    <img style={{ width: '16px', height: '16px',display:'flex', justifyContent:'center',alignItems:'center',margin: 'auto'}} src={ImageUrl + '/ChallengeDetailsScreen/updatedCheckmark.svg'}></img>
                  ) : (
                    <svg
                      style={{ margin: "auto" }}
                      width="12"
                      height="4"
                      viewBox="0 0 12 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="12" height="4" rx="2" fill="url(#a)" />
                      <defs>
                        <linearGradient
                          id="a"
                          x1="-.133"
                          y1="7.839"
                          x2="11.696"
                          y2="7.839"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#00B1BC" />
                          <stop offset="1" stopColor="#75D9A3" />
                        </linearGradient>
                      </defs>
                    </svg>
                  )}
                </HoverableDiv>

                <div>
                  <span className="select-page">Select All Inactive</span>
                </div>
                { inactiveUserCount > 20 || remainingInactiveUser > 20  ?
                  (<div style={{display:'flex',justifyContent:'center', alignItems:'center'}}><div
                    style={{
                      marginLeft: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      minHeight:'70px'
                    }}
                  >
                    <HoverableDiv  style={{ marginLeft: '20px'}}
                      onClick={() => this.handelSelectPage(this.state.selectPage)}
                      // onClick={this.handleSelectPage}
                    >
                      {selectPage < 1 ? (
                        ""
                      ) : selectPage < 2 ? (
                        <img style={{ width: '16px', height: '16px',display:'flex', justifyContent:'center',alignItems:'center',margin: 'auto'}} src={ImageUrl + '/ChallengeDetailsScreen/updatedCheckmark.svg'}></img>
                      ) : (
                        <svg
                          style={{ margin: "auto" }}
                          width="12"    
                          height="4"
                          viewBox="0 0 12 4"
                          fill="none"      
                          xmlns="http://www.w3.org/2000/svg"  
                        >
                          <rect width="12" height="4" rx="2" fill="url(#a)" />
                          <defs>
                            <linearGradient
                              id="a"
                              x1="-.133"
                              y1="7.839"
                              x2="11.696"
                              y2="7.839"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#00B1BC" />
                              <stop offset="1" stopColor="#75D9A3" />
                            </linearGradient>
                          </defs>
                        </svg>
                      )}
                    </HoverableDiv>
                  </div>
                  <div>
                    <span className="select-page">Select Page</span>
                  </div></div>):null}
              </div>) : null
            }
   
            <ButtonsContainer>
              {this.state.selectedParticipant.length > 0 ? (
                <span
                  style={{
                    margin: "auto 15px",
                    height: "auto",
                    fontSize: "16px",
                    color: '#005C87',
                  }}
                >
                  {`${selectAll===1 && !this.props.searchText && !this.props.locationText && !this.props.departmentText ? remainingInactiveUser : this.state.selectedParticipant.length} User${selectAll===1&& remainingInactiveUser >1?'s':this.state.selectedParticipant.length >1?'s':'' } Selected`}
                  {/* {`${selectAll===1 ?  ( inactiveUsersList < 20  ? this.state.selectedParticipant.length : remainingInactiveUser) : this.state.selectedParticipant.length} User${selectAll===1&& remainingInactiveUser >1?'s':this.state.selectedParticipant.length >1?'s':'' } Selected`} */}
                </span>
              ) : (
                ""
              )}
              {/* {this.state.selectedParticipant.length > 0 ? ( */}
              <SendRemainderButton
                disabled={!isButtonEnabled}
                onClick={isButtonEnabled ? () => this.handleSendReminder(this.state.selectedParticipant) : null
                }
              >
              Invite Users
              </SendRemainderButton>
              <DeleteUserButton
                disabled={!isButtonEnabled}
                onClick={isButtonEnabled ? () => this.showDeletePopup(this.state.selectedParticipant): null}
              >
              Remove User
              </DeleteUserButton>
            </ButtonsContainer>
          </ControlButtonsBar>
        </div>
        <div className="notScroll" style={{ marginTop: showAll===0 ? '0px': '25px'}}>
        
          <RowCardPeopleHeader inactiveUser={true}
            isShowDepartment={!isShowDepartment}
            isShowLocation={!isShowLocation}
          >
            <div className="userHeading">Users</div>
            {isShowDepartment?<div className="departmentHeading">Departments</div>:null}
            {isShowLocation?<div className="locationHeading">Locations</div>:null}
            <div className="emailHeading">Email</div>
            <div className="reminderHeading">Reminder Sent</div>
          </RowCardPeopleHeader>
          {!(inactiveUsersList === null) &&
           !(inactiveUsersList === undefined) &&
           inactiveUsersList.length > 0 && !_.isNull(inactiveUsersList[0]) ? (
              inactiveUsersList && inactiveUsersList.map((employee, index) => (
                <RowCardPeopleView 
                  key={index} 
                  onClick={() => showEmployeeDetails(employee.id)}
                  active={selectedEmployee === employee.id}  deleted={0} inactiveUser={true}
                  // hasDepartmentData={departmentData && departmentData.length > 0 && departmentData[0].department !== null}
                  // hasLocationData={locationData && locationData.length > 0 && locationData[0].department !== null}
                  isShowDepartment={!isShowDepartment}
                  isShowLocation={!isShowLocation}>
                  <div className= "rowContent">
                    <div className="wrapper">

                      {employee.days_left>=0 ?(
                        <div style={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                          <div 
                            style={{
                              width: '24px',
                              height: '24px',
                              border: employee.days_left !== 0 ? '1px solid rgba(0, 122, 177, 0.1)' : '1px solid #007AB1',borderRadius: '21px', 
                              display: 'flex' }} 
                            onClick={() => {
                              if (employee.days_left === 0) {
                                this.activeTick(employee.id);
                              }
                            }} >
                            {this.state.selectedParticipant.includes(employee.id) && (
                              <img style={{ width: '16px', height: '16px',display:'flex', justifyContent:'center',alignItems:'center',margin: 'auto'}} src={ImageUrl + '/ChallengeDetailsScreen/updatedCheckmark.svg'}></img>
                            )}
                          </div> 
                        </div>): null}
                      <div className="user">
                        <div className="name">{t(this.capitalizeFirstLetter(employee.name))}</div>
                      </div>
                    </div>
                    { isShowDepartment  ?
                      <div className="no-data">{employee && employee.department?employee.department:"-"}</div> :null
                    }
                    { isShowLocation ?
                      <div className="no-data">{employee && employee.location?employee.location:"-"}</div> : null
                    }

                    <div className="email">{t(employee.user_email)}</div>
                    {employee.days_left !== 0 && 
                   <div className="reminderText">
                     <p style={{color:'#ffff',textAlign:'center',margin:'auto', fontSize:'14px',fontFamily:" Rubik"}}>{`Sent ${employee.days_left} day${employee.days_left > 1 ? 's' : ''} ago`}</p>
                   </div>}

                  </div>
                </RowCardPeopleView>
              ))
            ) : (
              <Row disableCursor={1} align={1}>
                {t("No Employee")}
              </Row>
            )}
          {!searchAmigo && (
            <ParticipantLocationPopup
              companyId={plp.companyId}
              fetchEditLocationApi={plp.fetchEditLocationApi}
              show={plp.show}
              departmentData={plp.departmentData}
              handleShowNote={plp.handleShowNote}
              data={plp.data}
              showModalPopUp={plp.showModalPopUp}
              selectedParticipant={this.state.selectedParticipant}
              companyAuthDetails={plp.companyAuthDetails}
            />
          )}
          { this.state.showPopup &&
            <StyledModal show={this.state.showPopup} width={"485px"}>
              <CalendarHeader style={{background:'#005C87'}}>
                <div>{"Confirmation"}</div>
              </CalendarHeader>
              <CalendarWrapper padding="0 25px 25px 25px" style={{display:'flex', flexDirection:'column', justifyItems:'center'}} >
                <div style={{margin:'auto',marginTop:'25px'}}>
                  <LazyImage style={{width:'130px', height:'130px'}} src={'https://s3.amazonaws.com/images.trainingamigo.com/images/PeoplePage/thank-you-popup.png'} alt="popup image" />
                </div>
                <ConfirmVisitText style={{marginBottom:'5px'}}>{"Reminder Email Send Successfully to"}</ConfirmVisitText>
                {/* {`${selectAll===1 && !this.props.searchText ? remainingInactiveUser : this.state.selectedParticipant.length} User${selectAll===1&& remainingInactiveUser >1?'s':this.state.selectedParticipant.length >1?'s':'' } Selected`} */}
               
                {selectAll===1 && !this.props.searchText && !this.props.locationText && !this.props.departmentText  ?<p style={{textAlign:'center', color:'#007AB1', fontSize:'16px', fontWeight:'500', marginBottom:'20px'}}>{`Total ${remainingInactiveUser} Employee${remainingInactiveUser >1?'s':'' }`   }</p>:
                  <p style={{textAlign:'center', color:'#007AB1', fontSize:'16px', fontWeight:'500', marginBottom:'20px'}}>{`Total ${this.state.ReminderEmployess} Employee${this.state.ReminderEmployess >1?'s':'' }`   }</p>}
                <p style={{border:'1px solid #c4c4c4', margin:'0px -25px', marginBottom:'20px'}}></p>
                <ButtonContainer style={{justifyContent:"center"}}>
                  <ButtonV2
                    background="#007AB1"
                    backgroundHover= "#005C87"
                    onClick={() => this.handlePopup(false)}
                    style={{width:'100%'}}
                  >
                    {"Thank You"}
                  </ButtonV2>
                </ButtonContainer>
              </CalendarWrapper>
            </StyledModal>
          }
          { this.state.showDeleteUserPopup &&
            <StyledModal show={this.state.showDeleteUserPopup} width={"400px"} height={"292px"}>
              <CalendarHeader deleteInactiveUser={true} style={{background:'none',borderBottom:'1px solid rgba(0, 0, 0, 0.21)'}}>
                {/* <div>{WarningIcon()} Remove User</div> */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginRight: '10px' }}>{WarningIcon()}</div>
                  <div>Remove User</div>
                </div>
              </CalendarHeader>
              <CalendarWrapper padding="0 25px 25px 25px">
                {
                  <ConfirmVisitText deleteInactiveUser={true}>
                  Are you sure you want to remove
                  Users profile?
                  </ConfirmVisitText>
                }
                <ButtonContainerV1>
                  <ButtonV2

                    background="#007AB1"
                    backgroundHover= "#005C87"
                    width="352px"
                    height="48px"
                    onClick={() => this.closUserDeletePopup()}
                  >
                    No, Cancel
                  </ButtonV2>
                  <ButtonV2
                    border="1"
                    width="352px"
                    height="48px"
                    background="transparent"
                    color="#007AB1"
                    margin="5px 0 0 0"
                    onClick={()=>this.deleteInactiveUser(deleteTeamIDS)}
                  >
                    Yes, Remove
                  </ButtonV2>
                </ButtonContainerV1>
              </CalendarWrapper>
            </StyledModal>
          }
        </div>
        
      </Layout>
    );
  }
}

InactiveUsersListView.propTypes = {
  employeeList: PropTypes.array.isRequired,
  showEmployeeDetails: PropTypes.func.isRequired,
  selectedEmployee: PropTypes.any,
  changeOrder: PropTypes.func,
  searchAmigo: PropTypes.bool,
  history: PropTypes.object,
  challenge: PropTypes.object,
  sendFriendRequestPost: PropTypes.func,
  t: PropTypes.func,
  plp:PropTypes.object,
  inactiveUsersList:PropTypes.array,
  locationData:PropTypes.array,
  departmentData:PropTypes.array,
  show: PropTypes.boolean,
  showAll: PropTypes.number,
  inactiveUserCount: PropTypes.number,
  remainingInactiveUser: PropTypes.number,
  updateParentState: PropTypes.func.isRequired,
  fetchInactiveUsersList: PropTypes.func,
  isShowDepartment: PropTypes.number,
  isShowLocation: PropTypes.number,
  daysLeft:PropTypes.bool,
  searchText:PropTypes.string,
  locationText:PropTypes.string,
  departmentText:PropTypes.string,
};

const mapStateToProps = (state) => ({
  sentRequest: state.social.sentRequest
});

export default connect(mapStateToProps, null)(withTranslation()(InactiveUsersListView));