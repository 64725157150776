import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Image from '../Image';
import { NameSection, OtherSections, ImageContainer, EmployeeName, Row, EmployeeInterestContainer, Designation, MutualHeading } from './styles';
import { Button } from '../PeopleHomeV2/styles';
import { ImageUrl } from "../../utils/constants";
import { LevelText, LevelTextNew } from "../PeopleGridViewV2/styles";
import { connect } from 'react-redux';
import { sendFriendRequest } from '../../redux/actions';

const PeopleListView = ({ employeeList, selectedEmployee, searchAmigo, sendFriendRequest}) => (
  <div style={{"margin-top":"9px"}}>
    <div className={!(_.isNull(employeeList)) && !(_.isUndefined(employeeList)) && employeeList.length > 5 ?
      "scrollGrid" : 'notScroll'} >
      {
        !(_.isNull(employeeList)) && !(_.isUndefined(employeeList)) && employeeList.length > 0
          ?
          employeeList.map((employee, index) => (
            <Row key={index}  
              active={selectedEmployee === employee.uid}
              borderRadius={index === 0} newDeleted={employee.is_user_deleted ? 1 : 0} deleted={employee.is_user_deleted && !searchAmigo}>
              <NameSection>
                <div>
                  <ImageContainer>
                    <Image image={employee.profile_image} />
                  </ImageContainer>
                  <div style={{ "width": "35%" }}>
                    {
                      (employee.is_champion) ?
                        <LevelTextNew bgColor={'bgColor'} bottom={employee.department}>
                          <img src="/public/images/PeoplePage/Ribbon_img.png" />
                          <div>{'Wellness Champion'}</div>
                        </LevelTextNew>
                        :
                        <LevelText color="#FBFCFC" >{''}</LevelText>
                    }
                    <EmployeeName color="#3A3A3A" active={selectedEmployee === employee.uid} >
                      {employee.fullname}
                    </EmployeeName>
                    <Designation >
                      <p>{employee.department}</p>
                    </Designation>
                  </div>
                  <div>
                    <EmployeeInterestContainer active={selectedEmployee === employee.uid} newPosition={'newPosition'}>
                      {employee.interests && employee.interests.map((Wellness, index) => (
                        index < 4 &&
                        <div key={index}>
                          <img src={`${ImageUrl}/${Wellness.interest_icon}`} />
                        </div>
                      ))}
                    </EmployeeInterestContainer>
                  </div>
                </div>
              </NameSection>
              <div className="challenge-user">
                <MutualHeading>{employee.total_global_points || '0'}<span style={{"marginLeft":"5px"}}>Pts</span></MutualHeading>
                {
                  employee.friend === 0 ?
                    <Button listColor={'listColor'} profileColor={'profileColor'} fontFamily={'Rubik-Medium'} height={'0px'}
                      mutualData={employee.mutual_friends.length > 1} newData={employee.mutual_friends.length === 1
                        || employee.mutual_friends.length === 2} onClick={() => sendFriendRequest(employee.uid, employee.request_status)}>
                      add buddy</Button> : employee.friend === 1 ?
                      <Button fontFamily={'Rubik-Medium'} newData={employee.mutual_friends.length === 1
                        || employee.mutual_friends.length === 2} listColor={'listColor'}
                      greenProfileColor={'GreenProfileColor'} mutualData={employee.mutual_friends.length >= 1}>buddies</Button> : employee.friend === 2 ?
                        <Button fontFamily={'Rubik-Medium'} newData={employee.mutual_friends.length === 1 ||
                          employee.mutual_friends.length === 2} listColor={'listColor'} yellowProfileColor={'YellowProfileColor'} mutualData={employee.mutual_friends.length >= 1}>pending</Button> : <div style={{ "width": "160px", "height": "70px" }} />
                }
              </div>
              {searchAmigo &&
                <OtherSections interest={'15%'}>{employee.location || 'NA'}</OtherSections>
              }

            </Row>
          ))
          :
          <Row disableCursor={1} align={1}>
            No Employee
          </Row>
      }
    </div>
  </div>
);

PeopleListView.propTypes = {
  employeeList: PropTypes.array.isRequired,
  showEmployeeDetails: PropTypes.func.isRequired,
  selectedEmployee: PropTypes.any,
  changeOrder: PropTypes.func,
  searchAmigo: PropTypes.bool,
  sendFriendRequest: PropTypes.func,
  challenge: PropTypes.object,
  history: PropTypes.object
};

const mapStateToProps = (state) => ({
  sentRequest: state.social.sentRequest
});

const mapDispatchToProps = (dispatch) => ({
  sendFriendRequest: (data) => dispatch(sendFriendRequest(data))

})

export default connect(mapStateToProps, mapDispatchToProps)(PeopleListView);
